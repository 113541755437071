import React, { useEffect, useState } from 'react';
import TabContent from './components/TabContent';
import users from './data/users';
import './Splash.css';
import { Nav, NavItem, NavLink, TabContent as BootstrapTabContent, TabPane } from 'reactstrap'; // Import Bootstrap components

function Splash() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid');

    // Find user based on UID
    const foundUser = users.find((u) => u.uid === uid);
    setUser(foundUser);
  }, []);

  if (!user) {
    return <div>Loading or User Not Found</div>;
  }

  
  return (
   
     <body class="welcome">
  <span id="splash-overlay" class="splash"></span>
  <span id="welcome" class="z-depth-4"></span>


  <main class="valign-wrapper">
    <span class="container grey-text text-lighten-1 ">
      <p class="flow-text">Welcome to</p>
      <h1 class="title grey-text text-lighten-3">Speed Dating</h1>
      <blockquote class="flow-text">A place to find out stuff</blockquote>
    </span>
  </main>


  <footer class="page-footer deep-purple darken-3">
    <div class="footer-copyright deep-purple darken-4">
      <div class="container">
        <time datetime="{{ site.time | date: '%Y' }}">&copy; 2024 Galbraith</time>
      </div>
    </div>
  </footer>
</body>




     
   
  );
}

export default Splash;
