import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import {Spinner} from "reactstrap"

function Splashscreen() {
  const [loading, setLoading] = useState(true); // Loading state
  const [responses, setResponses] = useState(null); // Responses state
  const [uid, setUid] = useState(''); // State to store the user input UID
  const navigate = useNavigate(); // Use navigate for navigation

  // Simulating a loading scenario
  useEffect(() => {
    setTimeout(() => {
      // Simulate loading complete after 2 seconds
      setLoading(false);
      setResponses(true); // Simulate successful response (set to 'false' to test the "Question Not Found" case)
    }, 2000);
  }, []);

  // Handle form submission and redirection
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    if (uid) {
      navigate(`/dating/?uid=${uid}`); // Redirect to the desired page with the UID parameter
    }
  };

  // Display loading message when loading
  if (loading) {
    return (
      <div className="App container" style={styles.splashContainer}>
         <div style={styles.splashContent}>
    <div style={styles.loadingContainer}>
    <Spinner style={styles.spinner} /> {/* Spinner component */}
    </div>
  </div>
  </div>
    )
  }

  // Display a message if responses are not found
  if (!responses) {
    return <div>Question Not Found</div>;
  }

  // Render splash content once loaded and responses are available
  return (
    <div className="App container" style={styles.splashContainer}>
      <div style={styles.splashContent}>
        <h2>Speed Dating</h2>
        <p>Välkommen!</p>

        {/* Input form */}
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            value={uid}
            onChange={(e) => setUid(e.target.value)}
            placeholder="Din ID"
            style={styles.inputBox}
          />
          <button type="submit" style={styles.button}>
            Gå!
          </button>
        </form>
      </div>
    </div>
  );
}

// CSS styles using JavaScript objects
const styles = {
  splashContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers horizontally
    alignItems: 'center', // Centers vertically
    height: '100vh', // Full viewport height
  },
  splashContent: {
    textAlign: 'center', // Center text content
  },
  form: {
    marginTop: '20px', // Spacing above form
  },
  inputBox: {
    padding: '10px',
    marginRight: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
};

export default Splashscreen;
