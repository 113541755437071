import React, { useEffect, useState } from 'react';
import OpenAI from 'openai';
import Markdown from 'react-markdown'
import {Button} from "reactstrap"


function TabContent({ question, table, uid, questionIndex }) {
  const [aiResponse, setAiResponse] = useState(''); // State for the AI response
  const [userResponse, setUserResponse] = useState(''); // State for the user's written response
  const [loadingAi, setLoadingAi] = useState(false); // State for loading AI response
  const [loadingSubmit, setLoadingSubmit] = useState(false); // State for loading submission
  const [submitted, setSubmitted] = useState(false); // State for submission status
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [assistant, setAssistant] = useState(null);
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  
  useEffect(() => {
    initChatBot();
  }, []);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: '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',
      dangerouslyAllowBrowser: true,
    });

    // Create an assistant
    const assistant = await openai.beta.assistants.retrieve('asst_BrDMhxr7KW0RKNzLJlwmiu4z')
    // Create a thread
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  // Function to handle calling ChatGPT (or AI service) manually
  const generateAiResponse = async () => {
    setLoadingAi(true); // Set loading state while AI response is generated
    setErrorMessage(''); // Clear any previous error messages


    try {
        const threadMessages = await openai.beta.threads.messages.create(
            thread.id,
            { role: "user", content: question }
          );

          // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistant.id,
      });

      // Create a response
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        
    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
        console.log("waiting...");
       
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      }

      const messageList = await openai.beta.threads.messages.list(thread.id);

      // Find the last message for the current run
    const lastMessage = messageList.data
    .filter((message) => message.run_id === run.id && message.role === "assistant")
    .pop();

    if (lastMessage) {
        console.log(lastMessage.content[0]["text"].value);
        setAiResponse(lastMessage.content[0]["text"].value);
       // setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
      }
          console.log(messageList);

      
    } catch (error) {
      setErrorMessage('Error fetching AI response. Please try again later.');
    } finally {
      setLoadingAi(false); // Remove loading state after AI response
    }
  };

  // Function to handle user response submission
  const submitUserResponse = async () => {
    if (!userResponse.trim()) {
      setErrorMessage('Response cannot be empty.');
      return;
    }

    setLoadingSubmit(true); // Set loading state while submitting
    setErrorMessage(''); // Clear any previous error messages

    try {
      const response = await fetch('https://dating-server.teed.se/store', {
        method: 'POST',
        body: JSON.stringify({
          uid: uid,
          group: table, // The user's group (or table)
          question: questionIndex + 1, // Index of the question, add 1 for 1-based indexing
          text: userResponse,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true); // Show success message if submission is successful
        setUserResponse(''); // Clear the text box
      } else {
        throw new Error('Failed to submit response');
      }
    } catch (error) {
      setErrorMessage('Error submitting your response. Please try again later.');
    } finally {
      setLoadingSubmit(false); // Remove loading state after submission
    }
  };

  return (
    <div className="tab-content">
      <p></p>
      <h4>Bord: {table}</h4>
     
      <h4>Fråga:</h4>
      <p>{question}</p>
      

      {/* Button to generate AI response */}
      <button onClick={generateAiResponse} disabled={loadingAi} style={styles.button}>
        {loadingAi ? 'Edvina funderar...' : 'Fråga Edvina..'}
      </button>

      {/* Display AI response if available */}
      {aiResponse && (
        <div className="ai-response mt-3">
          <h4>AI Svar:</h4>
          <Markdown children={aiResponse}/>
        </div>
      )}

      {/* Display error message if available */}
      {errorMessage && (
        <div className="error-message mt-3" style={{ color: 'red' }}>
          {errorMessage}
        </div>
      )}

      {/* Textbox for user's response */}
      <div className="user-response mt-4">
        <h4>Ert svar:</h4>
        <label htmlFor="userResponse" className="sr-only">
        (Enskild eller grupp)
        </label>
        <textarea
          id="userResponse"
          value={userResponse}
          onChange={(e) => setUserResponse(e.target.value)}
          placeholder="Skriv texten här"
          rows="5"
          style={{ width: '100%' }}
          aria-label="Your written response to the question"
        />
      </div>

      {/* Submit button for user's response */}
      <button
        className="mt-3"
        onClick={submitUserResponse}
        disabled={!userResponse.trim() || loadingSubmit}
        style={styles.button}
      >
        {loadingSubmit ? 'Skickar...' : 'Skicka svar'}
      </button>

      {/* Success message on submission */}
      {submitted && (
        <p className="text-success mt-2">Ditt svar har sparats!</p>
      )}
    </div>
  );
}

// CSS styles using JavaScript objects
const styles = {
    splashContainer: {
      display: 'flex',
      justifyContent: 'center', // Centers horizontally
      alignItems: 'center', // Centers vertically
      height: '100vh', // Full viewport height
    },
    splashContent: {
      textAlign: 'center', // Center text content
    },
    form: {
      marginTop: '20px', // Spacing above form
    },
    inputBox: {
      padding: '10px',
      marginRight: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    button: {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
    },
  };

export default TabContent;
