import React, { useEffect, useState } from 'react';
import { OpenAI } from 'openai'; // Import OpenAI SDK
import { Spinner, Button } from "reactstrap";

function App() {
  const [responses, setResponses] = useState(null);
  const [activeTab, setActiveTab] = useState('0');
  const [loading, setLoading] = useState(true);
  const [no, setNo] = useState();
  const [summary, setSummary] = useState('');
  const [loadingSummary, setLoadingSummary] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const no = urlParams.get('no');
      setNo(no);
      try {
        const response = await fetch(`https://dating-server.teed.se/responses/${no}`);
        if (response.ok) {
          const userData = await response.json();
          setResponses(userData);
        } else {
          console.error('Error fetching responses:', response.status);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const summarizeResponses = async () => {
    setLoadingSummary(true);

    try {
      // Konfigurera OpenAI
      const configuration = {
        apiKey: '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',
      dangerouslyAllowBrowser: true,
      };
      const openai = new OpenAI(configuration);

      // Konkatenera alla svar till en sträng
      const text = responses.map((r) => r.text).join(' ');

      // Skicka en förfrågan till OpenAI för att sammanfatta
      const response = await openai.createCompletion({
        model: "gpt-4", // eller "gpt-3.5-turbo" beroende på vilket du vill använda
        prompt: `Sammanfatta följande svar: ${text}`,
        max_tokens: 150,
      });

      const summaryText = response.data.choices[0].text.trim();
      setSummary(summaryText); // Sätt sammanfattningen i state

    } catch (error) {
      console.error('Summarization error:', error);
    } finally {
      setLoadingSummary(false);
    }
  };

  if (loading) {
    return (
      <div className="App container" style={styles.splashContainer}>
        <div style={styles.splashContent}>
          <div style={styles.loadingContainer}>
            <Spinner style={styles.spinner} />
          </div>
        </div>
      </div>
    );
  }

  if (!responses) {
    return (
      <div style={styles.splashContainer}>
        <div style={styles.splashContent}>
          <div style={styles.buttonGroup}>
            <a href="/responses/?no=1" className="btn btn-primary">
              Fråga 1
            </a>
            <a href="/responses/?no=2" className="btn btn-primary">
              Fråga 2
            </a>
            <a href="/responses/?no=3" className="btn btn-primary">
              Fråga 3
            </a>
            <a href="/responses/?no=4" className="btn btn-primary">
              Fråga 4
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App container">
      <h2>Svar - fråga {no}</h2>
      {responses.map((response, index) => (
        <p key={index}>{response.text}</p>
      ))}

      {/* Summarize Button */}
      <button color="primary" onClick={summarizeResponses} disabled={loadingSummary}>
        {loadingSummary ? 'Sammanfattar...' : 'Sammanfatta'}
      </button>

      {/* Show Summary */}
      {summary && (
        <div>
          <h3>Sammanfattning:</h3>
          <p>{summary}</p>
        </div>
      )}
    </div>
  );
}

// CSS styles using JavaScript objects
const styles = {
  splashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  splashContent: {
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  spinner: {
    width: '3rem',
    height: '3rem',
  },
};

export default App;
