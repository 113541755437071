import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dating from './Dating';
import Results from './Results';
import Splash from './Splashscreen';

function App() {

  return (
    <div className="App">
       <Routes>
         <Route path='/' element={<Splash/>} />
         <Route path='/dating' element={<Dating/>} />
         <Route path='/responses' element={<Results/>} />
       </Routes>
     </div>
  );
}

export default App;
