import React, { useEffect, useState } from 'react';
import TabContent from './components/TabContent';
import { Spinner, Nav, Navbar, NavbarBrand, NavItem, NavLink, TabContent as BootstrapTabContent, TabPane } from 'reactstrap'; // Import Bootstrap components

function Dating() {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('0'); // Track the active tab with a string (Bootstrap expects strings)
  const [loading, setLoading] = useState(true); // Add loading state

  console.debug("Starting")
  useEffect(() => {
    const fetchUserData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const uid = urlParams.get('uid');
      
      try {
        // Fetch the user data from the backend
        const response = await fetch(`https://dating-server.teed.se/fetch/${uid}`);
        if (response.ok) {
          const userData = await response.json();
          console.log(userData);
          setUser(userData); // Set the fetched user data
        } else {
          console.error('Error fetching user:', response.status);
        }
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false); // Data fetching complete
      }
    };

    fetchUserData(); // Call the fetch function
  }, []);

  if (loading) {
    return (
      <div className="App container" style={styles.splashContainer}>
         <div style={styles.splashContent}>
    <div style={styles.loadingContainer}>
    <Spinner style={styles.spinner} /> {/* Spinner component */}
    </div>
  </div>
  </div>
    )
  }

  if (!user) {
    return(
    <div className="App container" style={styles.splashContainer}>
    <div style={styles.splashContent}>
<div style={styles.loadingContainer}>
 <div>
  <h3>Användaren finns ej.</h3></div>
</div>
</div>
</div>
    )
  }

  const questions = [
    {
      question: "Saknar ni något innehåll eller är något överflödigt? Är riktlinjerna rimliga?",
    },
    {
      question: "Hur transparenta bör vi vara när vi som lärare använder AI gentemot elever? ",
    },
    {
      question: "Riktlinjerna nämner ”etisk dataanvändning”. Hur kan skolan säkerställa att AI-verktyg som används skyddar elevernas och personalens integritet? Några exempel där detta kan vara relevanta:\n inloggning och kontoskapande till olika AI tjänster \ntjänster som lagrar information och samtal \nbedömning av elevtexter som kan innehålla personlig information. ",
    },
    {
      question: "Begreppet ”tillgänglighet” förekommer i texten. Hur kan skolan inkludera olika grupper, som elever med funktionsnedsättningar eller olika socioekonomiska bakgrunder, i utvecklingen och användningen av AI, för att säkerställa att alla har lika tillgång till dessa verktyg? ",
    },
  ];

  return (
    <div className="App container">
       <Navbar  color="light" >
       <NavbarBrand href={"/dating/?uid="+user.uid}>Speed Dating</NavbarBrand>
       <Nav className="me-auto" navbar>
      
            </Nav>
      </Navbar>
      <Navbar>
      
      </Navbar>
      <p></p>
      <h2 className="mt-5">Välkommen, {user.first_name} {user.surname}.</h2>

      {/* Bootstrap Nav tabs */}
      <Nav tabs>
        {questions.map((q, index) => (
          <NavItem key={index}>
            <NavLink
              className={activeTab === String(index) ? 'active' : ''}
              onClick={() => setActiveTab(String(index))} // Convert index to string
              style={{ cursor: 'pointer' }}
            >
              Date {index + 1}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      {/* Bootstrap Tab content */}
      <BootstrapTabContent activeTab={activeTab}>
        {questions.map((q, index) => (
          <TabPane tabId={String(index)} key={index}>
            {activeTab === String(index) && (
              <TabContent
              uid={user.uid}
              questionIndex={index}
                question={q.question}
                table={user.groups[index]} // Assuming groups is the equivalent of tables
                key={"table" + index}
              />
            )}
          </TabPane>
        ))}
      </BootstrapTabContent>
    </div>
  );
}

// CSS styles using JavaScript objects
const styles = {
  splashContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers horizontally
    alignItems: 'center', // Centers vertically
    height: '100vh', // Full viewport height
  },
  splashContent: {
    textAlign: 'center', // Center text content
  },
  form: {
    marginTop: '20px', // Spacing above form
  },
  inputBox: {
    padding: '10px',
    marginRight: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  },
};

export default Dating;
